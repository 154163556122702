import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../layouts'
import LargeHero from '../../components/elements/largeHero'
import { Body, SizedText, Canon } from '../../components/elements/typography'
import Grid from '../../components/elements/grid'
import { graphql, Link } from "gatsby"
import SEO from '../../components/elements/seo'

const Article = ({date, location, title, excerpt, slug}) => {
  return (
    <article css={{
      padding: `3rem 2.5rem`,
      borderBottom: `1px dotted #000`,
      hyphens: `auto`,
    }}>
      <small css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`,  color: `#7f8c8d`, margin: `3rem 0 0 0`, 'span': {display: `block`, lineHeight: `1`, padding: `0`}}}>
        <SizedText as="span" smallSize="1" largeSize="1.125">
          {date} - {location}
        </SizedText>
      </small>
      <h1 css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`,hyphens: `auto`,  margin: `0 0 2rem 0`, 'span': {display: `block`, lineHeight: `1`, padding: `0.5rem 0`, hyphens: `auto`,}}}>
        <SizedText as="span" smallSize="2.281" largeSize="2.887">
          {title}
        </SizedText>
      </h1>
      <Body css={{
        textAlign: `justify`,
      }}>
        {excerpt}
      </Body>
      <div css={{paddingTop: `2rem`}}>
        <Link to={slug}>Read Full Story</Link>
      </div>
    </article>
  )
}



export default function NewsPage({
  data, // this prop will be injected by the GraphQL query below.
}) {

  const articles = data.allMarkdownRemark.nodes.map(i => (
    <Article date={i.frontmatter.date} location={i.frontmatter.location} title={i.frontmatter.title} excerpt={i.excerpt} slug={i.frontmatter.slug} />
  ))

  return (
    <Layout isSubMenuOpen={true} openAccountLink={true}>

      <SEO
        title="TCB Newsroom for the Latest Press Releases"
        description="The TCB newsroom provides the latest news and journalist resources so you can stay informed and up to date."
        location="/about/news"
        ogImg='/images/social-graph/the-claxton-bank.jpg'
      />  

      <LargeHero.Container
        padding="3rem 0 4rem 0"
        background={{
          backgroundImage: `url(/images/background-deltas.jpg)`, 
          backgroundRepeat: `no-repeat`, 
          backgroundAttachment: `scroll`, 
          backgroundPosition: `top left`, 
          backgroundSize: `cover`,
        }}
        breadcrumbLinks={[
          { path: "/", text: "Home" },
          { path: "/about/", text: "About Us" },
          { path: "/about/news/", text: "News" },
        ]}
        styles={{
          paddingBottom: `5rem`,
          overflow: `hidden`,
          '.breadcrumbs, .breadcrumbs [aria-current="page"]': {color: `#fff`},
          '.heroContent': { paddingBottom: `3rem`},
          '@media (min-width: 680px)': {
            '.heroContent': { width: `calc(72% - 1rem)`, marginBottom: `0`},
            '.heroImage': { width: `calc(40% - 1rem) !important`,},
          }
        }}
      >
        <LargeHero.Content styles={{paddingTop: `1rem`, color: `#fff`}}>
          <LargeHero.Eyebrow text="About Us" styles={{borderColor: `#fff !important`}}/>
          <h1 css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`,  'span': {display: `block`, lineHeight: `1`, padding: `0.5rem 0`}}}>
            <SizedText as="span" smallSize="2.281" largeSize="4.624">News & Press Releases</SizedText>
          </h1>
        </LargeHero.Content>
        <LargeHero.Image >
          <StaticImage 
              css={{
                '@media (min-width: 680px)': { display: 'none !important' }, 
                '@media (min-width: 1300px)': { display: 'block !important' } 
              }} 
              src="../../images/newsroom-hero.png" 
              alt=""
            />
            <StaticImage 
              css={{
                display: `none !important`,  
                '@media (min-width: 680px)': { display: 'block !important' }, 
                '@media (min-width: 1300px)': { display: 'none !important' } 
              }} 
              src="../../../images/policies_cropped.png" 
              alt=""
            />
        </LargeHero.Image>
      </LargeHero.Container>

      <Grid.FullWidthSection css={{padding: `4rem 0`, backgroundColor: `#fff`}}>

        <Grid.Block start='6' span="7" css={{}}>
          {articles}
        </Grid.Block>
        <Grid.Block start='3' span="3" css={{gridRowStart: `-1`, overflow: `hidden`}}>
          <div>
          <div css={{
            marginTop: `2.5rem`,
            borderTop: `5px solid #d7d7d7`,
            borderBottom: `5px solid #d7d7d7`,
            padding: `2rem 2rem 4rem 2rem`,
  
          }}>
            <SizedText as="h2" smallSize="1.602" largeSize="2.027" styleOverrides={{ fontFamily: `source-sans-pro !important`, fontWeight: `300 !important`, marginBottom: `3rem`}}>Want to connect with media relations?</SizedText>
            <SizedText as="p" smallSize="1" largeSize="1.266" styleOverrides={{ fontFamily: `source-sans-pro !important`, fontWeight: `300 !important` }}>Troy A. Poulin</SizedText>
            <Body><a href="">tpoulin@tcbga.bank</a></Body>
          </div>
          <div css={{
            padding: `2rem`,
            '@media (min-width: 736px)': {
              paddingTop: `0.5rem`
            },
            '@media (min-width: 1024px)': {
              paddingTop: `2rem`,
              //borderBottom: `2px solid #bdc3c7`,
            },
            'ul': {
              padding: `1rem 0 0 0`,
              width: `300px`,
              listStyle: `none`,
              'li a': {
                display: `block`,
                padding: `0.25rem 0` 
              }
            }
          }}>
            <SizedText as="h2" smallSize="1.266" largeSize="1.424">
              Quick Links Menu
            </SizedText>
            <ul>
              <li><Link to="">Branch Locations</Link></li>
              <li><Link to="">Holiday Schedule</Link></li>
              <li><Link to="">Report a Security Incident</Link></li>
              <li><Link to="">Understanding Your Balance</Link></li>
              <li><Link to="">Debit Card FAQs</Link></li>
              <li><Link to="">Digital Banking FAQs</Link></li>
              <li><Link to="">Mobile Deposit FAQs</Link></li>
              <li><Link to="">Loan FAQs</Link></li>
            </ul>
          </div>

          </div>
        </Grid.Block>
      </Grid.FullWidthSection>  
    </Layout>
  )
}


export const pageQuery = graphql`
query NewsQuery {
  allMarkdownRemark(limit: 10, sort: {order: DESC, fields: frontmatter___date}) {
    nodes {
      excerpt(pruneLength: 300)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        location
        slug
        title
      }
    }
  }
}
`